<template>
  <div>

    <CRow>
      <CCol lg="12">
        <CCard>
        <CCardHeader>
          <slot name="header">
            <h4 class="float-left">Data Taman</h4> 
            <span style="float:right">
              <a href="/#/taman/add" class="btn btn-success form-control-sm" style='margin-right:15px'>Tambah Data Taman</a>
              <CButton color="dark" :href="hrefExportExcel" size="sm" style='margin-right:5px'>Excel</CButton>
              <CButton color="danger" :href="hrefExportPdf" size="sm">PDF</CButton>
            </span>
          </slot>
        </CCardHeader>
        <CCardBody>
      
        <table ref="table" class="table" id="datatable" :options="{ deleteTaman }">
          <thead>
            <tr>
              <th style="width:10px">#</th>
              <th>Nama </th>
              <th>Alamat </th>
              <th style="width:100px">Kecamatan </th>
              <th style="width:60px">Luas (M2) </th>
              <th>Gambar</th>
              <th style="width:50px">Edit</th>
              <th style="width:50px">Hapus</th>
            </tr>
          </thead>
          <tbody>

          </tbody>
        </table>  

    </CCardBody>
  </CCard>  
  
  </CCol>
    </CRow>
    <CModal
      title="Foto Taman"
      :show.sync="modalImg"
      
    >
      <img :src="imgThumbnail" ref="imgModal" class="img-fluid" />
    </CModal>
    <CModal
      :show.sync="deleteModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Hapus Data"
      color="danger"
    >
      Anda Yakin Ingin Menghapus Data Ini?
      <template #header>
        <h6 class="modal-title">Hapus Data</h6>
        <CButtonClose @click="darkModal = false" class="text-white"/>
      </template>
      <template #footer>
        <CButton @click="deleteModal = false" color="light">Batal</CButton>
        <CButton @click="deleteTaman()" color="danger">Hapus</CButton>
      </template>
    </CModal>
  </div>
</template>
 
<script>
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import $ from 'jquery'; 
import html2pdf from 'html2pdf.js';
import * as XLSX from 'xlsx';

export default {
  
  filters: {
    rupiah: function(value) {
      // return value.toUpperCase();
      // Create our number formatter.
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        
      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  mounted(){
    console.log(process.env.VUE_APP_BASE_URL);
    this.loadData();
  },
  data: function() {
        return {
          hrefExportExcel: process.env.VUE_APP_BASE_URL+'taman-excel',
          hrefExportPdf: process.env.VUE_APP_BASE_URL+'taman-pdf',
          modalImg: false,
          imgThumbnail: '',
          deleteModal : false,
            idselected:"0",
            products:[],
            items:[],
            itemlengths:0,
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    exportToPDF() {
      console.log('')
      const element = this.$refs.table; // Reference to the HTML element (table)
      html2pdf(element);
    },
    exportToExcel() {
      console.log('')
      const ws = XLSX.utils.table_to_sheet(this.$refs.table); // Reference to the HTML table
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'exported-table.xlsx');
    },

    confirmDelete: function(id){
      this.idselected = id;
      this.deleteModal = true;
    },
    deleteTaman: function(){
      // alert(this.idselected);
      axios.delete(process.env.VUE_APP_BASE_URL+"taman/"+this.idselected)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.data.status==='success'){
              this.deleteModal = false;
              $(this.$refs.table).DataTable().draw();
            }
            else{
              this.myError = true;
            }
            
        })
    },
    loadData: function() {
               
      if (this.tahun == null){
      
          $('#datatable').DataTable({
            "processing": true,
              "serverSide": true,
              "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"taman/datatable",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no" },
                  { "data": "name" },
                  { "data": "address" },
                  { "data": "kecamatan" },
                  { "data": "luas", class:"text-center" },
                  { "data": "foto", class:"text-center" },
                  // { "data": "input", class:"text-center" },
                  { "data": "edit", class:"text-center" },
                  { 
                    "data": null, 
                    class:"text-center",
                    "render": function(data, type, row, meta) {
                            alert(data.id);
                            return "<button @click.prevent='deleteTaman(`"+data.id+"`)' data-id='$r[id]' class='btn btn-sm btn-danger btnhapus btn2' >Hapus</button>";
                        }, 
                    },
              ]  

          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      else{
        
         const table = $(this.$refs.table).DataTable({
                      "processing": true,
              "serverSide": true,
              "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"taman/datatable",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no" },
                  { "data": "name" },
                  { "data": "address" },
                  { "data": "kecamatan" },
                  { "data": "luas", class:"text-center" },
                  { "data": "foto", class:"text-center" },
                  // { "data": "input", class:"text-center" },
                  { "data": "edit", class:"text-center" },
                  { 
                    "data": null, 
                    class:"text-center",
                    "render": function(data, type, row, meta) {
                            // alert(data.id);
                            return "<button @click.prevent='deleteTaman(`"+row.id+"`)' class='btn btn-sm btn-danger btnhapus btn2' >Hapus</button>";
                        }, 
                    },
              ]  
           
          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
          const self = this
          $('tbody', this.$refs.table).on( 'click', '.btnhapus', function(){
              const cell = table.cell( $(this).closest("td") );
              const objdata =cell.data();
              // alert(objdata.id);
              self.confirmDelete(objdata.id)
          });
          const app = this;
           // Handle click event for the "foto" column
           $('tbody', this.$refs.table).on('click', '.foto-thumbnail', function() {
            const imgSrc = $(this).attr('src');
            app.imgThumbnail = imgSrc;
            app.modalImg = true;
        });
        // })
      }
      
    }
  }
}
</script>